<template>
  <div class="" v-if="model">
    <div class="board">
      <div class="boardrd first">
        <span v-if="model.status === 'ANSWER'" class="nbicon bkblbg">{{$t('front.qnaStatus.answer')}}</span>
        <span v-if="model.status === 'WAIT'" class="nbicon bgrbg">{{$t('front.qnaStatus.wait')}}</span>
        <span class="nbicon bgrbg">{{$t('front.qnaTypeCategory.' + model.faqType)}}</span>
        <span class="ml10">{{model.title}}</span>
      </div>
      <div class="boardrd">
        <!--span class="medal medal1"><em class="level">1</em></span-->
        <span>{{model.memName}}</span><span>{{dateFormatForBoard(model.regDate)}}</span>
      </div>
      <div class="boardbox">
        <p>{{model.content}}</p>
        <p class="rdcon rdconbg mt20" v-if="replyContent">
          {{replyContent}}
        </p>
      </div>
    </div>

    <div class="applibtns">
      <a v-if="prevId" @click="onClickPrevNext('prev')">{{$t('front.board.prev')}}</a>
      <a @click="onClickList">{{$t('front.board.list')}}</a>
      <a v-if="nextId" @click="onClickPrevNext('next')">{{$t('front.board.next')}}</a>
    </div>

  </div>
</template>

<script>
import { getBoardCookie } from '@/libs/auth-helper'

export default {
  name: 'qnaread',
  components: {
  },
  data () {
    return {
      boardType: 'faq',
      boardIdx: null,
      model: null,
      replyContent: '',
      prevId: null,
      nextId: null
    }
  },
  created () {
    this.boardIdx = this.$route.params.boardIdx
    this.loadBoardDetail(this.boardIdx)

    this.idxList = getBoardCookie().split(',')
    console.log(this.idxList)

    if (this.idxList.length > 0) {
      for (let i = 0, iLen = this.idxList.length; i < iLen; i++) {
        if (this.idxList[i] === this.boardIdx.toString()) {
          if (this.idxList[i - 1]) {
            this.prevId = this.idxList[i - 1]
          }
          if (this.idxList[i + 1]) {
            this.nextId = this.idxList[i + 1]
          }
        }
      }
    }
  },
  methods: {
    async loadBoardDetail (boardIdx) {
      const params = {
        boardIdx: boardIdx,
        boardType: this.boardType
      }
      this.model = await this.getBoardDetail(params)
      this.replyContent = this.model.comment.length > 0 ? this.model.comment[0].content : ''
    },
    onClickPrevNext (type) {
      let id = this.prevId
      if (type === 'next') {
        id = this.nextId
      }
      this.$router.push({ name: 'qnaread', params: { boardIdx: id } })
    },
    onClickList () {
      this.$router.push({ name: 'qna' })
    }
  }
}
</script>
<style src="@/styles/striNew.css"></style>
